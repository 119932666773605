import type { WheelData } from "@/types";

const useWheelController = () => {
	const { data } = useAppInitData();

	const initWheelPlugin = () => {
		const prefixPath = window.globalWheelData?.configPathPrefix;
		const script = document.createElement("script");
		script.src = `${prefixPath}resource/loader.js?v=${Math.random()}`;
		document.head.appendChild(script);
	};

	const handleSpin = (wheelType: "spin_win" | "lucky_spin") => {
		dispatchGAEvent({
			event: "click_button",
			location: "wheel",
			buttonName: wheelType
		});

		window.postMessage({ wheelEvent: "spinButtonPressed" }, "*");
	};

	const handleClose = () => {
		dispatchGAEvent({
			event: "click_button",
			location: "wheel",
			buttonName: "collect"
		});

		window.postMessage({ wheelEvent: "closeButtonPressed" }, "*");
	};

	const runWheelPlugin = () => {
		window.onWheelLoad();
	};

	const killWheel = () => {
		window.postMessage({ wheelEvent: "killWheel" }, "*");
	};

	const unlockWheel = () => {
		window.postMessage({ wheelEvent: "unlockWheel" }, "*");
	};

	const lockWheel = () => {
		window.postMessage({ wheelEvent: "lockWheel" }, "*");
	};

	const destroyDailyWheel = () => {
		window.globalWheelData = null;
		killWheel();
	};

	const initDailyWheel = ({ id, config }: { id: number; config: WheelData["wheelConfig"] }) => {
		localStorage.setItem("showedDailyWheelPopup", "true");

		window.globalWheelData = {
			id,
			enabled: false,
			lang: data.value?.language || "en",
			configPathPrefix: "/uploads/rank_league/wheel-fortune/",
			resourcePathPrefix: "/uploads/rank_league/wheel-fortune/",
			config
		};

		initWheelPlugin();
	};

	return {
		initWheelPlugin,
		runWheelPlugin,
		killWheel,
		unlockWheel,
		lockWheel,
		initDailyWheel,
		destroyDailyWheel,
		handleSpin,
		handleClose
	};
};

export default useWheelController;
